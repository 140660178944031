import { createApp, provide, h } from 'vue'
import App from './App.vue'
import { footerTableProps } from "@/helpers/micro-service";
import router from "./router";
import VueApexCharts from "vue3-apexcharts";
import vClickOutside from "click-outside-vue3";
import { registerScrollSpy } from 'vue3-scroll-spy';
import { DspAuth } from '@octillion/auth'

import { AgGridVue } from "ag-grid-vue3";
import "ag-grid-enterprise";
import { LicenseManager } from "ag-grid-enterprise";

// Set your AG Grid Enterprise license key from environment variables
LicenseManager.setLicenseKey(process.env.VUE_APP_AG_GRID_LICENSE_KEY);

import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";

import './registerServiceWorker'

import { vMaska } from "maska"
import i18n from "./i18n"
import { initFirebaseBackend } from './authUtils'
import { configureFakeBackend } from './helpers/fake-backend';

import BootstrapVueNext from 'bootstrap-vue-next'

import ToastPlugin from 'vue-toast-notification';
import VueFormWizard from 'vue3-form-wizard';
import 'vue3-form-wizard/dist/style.css';
// Import one of the available themes
//import 'vue-toast-notification/dist/theme-default.css';
import 'vue-toast-notification/dist/theme-bootstrap.css';


import 'bootstrap/dist/css/bootstrap.css'

import 'bootstrap-vue-next/dist/bootstrap-vue-next.css'
import "@/assets/scss/app.scss";
import "@vueform/multiselect/themes/default.css"
import "vue-multiselect/dist/vue-multiselect.css"
//import 'vue-js-toggle-button/dist/vue-js-toggle-button.css';

// PINIA
import pinia from '@/state/pinia'
import store from "./state/store";
import { Vue3ToggleButton } from 'vue3-toggle-button';

import rangeCalendar from 'vue3-range-calendar'
import 'vue3-range-calendar/dist/styles/index.css'
import '@octillion/auth/style.css'


const firebaseConfig = {
    apiKey: process.env.VUE_APP_APIKEY,
    authDomain: process.env.VUE_APP_AUTHDOMAIN,
    databaseURL: process.env.VUE_APP_VUE_APP_DATABASEURL,
    projectId: process.env.VUE_APP_PROJECTId,
    storageBucket: process.env.VUE_APP_STORAGEBUCKET,
    messagingSenderId: process.env.VUE_APP_MESSAGINGSENDERID,
    appId: process.env.VUE_APP_APPId,
    measurementId: process.env.VUE_APP_MEASUREMENTID
};

if (process.env.VUE_APP_DEFAULT_AUTH === "firebase") {
    initFirebaseBackend(firebaseConfig);
} else {
    configureFakeBackend();
}


// import {createClient, withProvider} from 'vue-gql';
// import {SubscriptionClient} from 'subscriptions-transport-ws';

// const subscriptionClient = new SubscriptionClient(process.env.VUE_APP_GRAPHQL_WS_URL, {});

// const client = createClient({
//     url: process.env.VUE_APP_GRAPHQL_URL,
//     subscriptionForwarder: op => subscriptionClient.request(op)
// });


import { createClient } from 'graphql-ws';
// const websocketClient = createClient({
//     url: process.env.VUE_APP_GRAPHQL_URL,
//     shouldRetry: () => true,
// });

// const AppWithClient = withProvider(App, websocketClient);

import { DefaultApolloClient } from '@vue/apollo-composable';
import { ApolloClient, InMemoryCache, HttpLink, split } from '@apollo/client/core';
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { getMainDefinition } from "@apollo/client/utilities";

// Create an http link:
const httpLink = new HttpLink({
    uri: process.env.VUE_APP_GRAPHQL_URL
})

// Create a GraphQLWsLink link:
const wsLink = new GraphQLWsLink(
    createClient({
        url: process.env.VUE_APP_GRAPHQL_WS_URL,
    })
);

// using the ability to split links, you can send data to each link
// depending on what kind of operation is being sent
const link = split(
    // split based on operation type
    ({ query }) => {
        const definition = getMainDefinition(query)
        return (
            definition.kind === "OperationDefinition" &&
            definition.operation === "subscription"
        )
    },
    wsLink,
    httpLink
)

const apolloClient = new ApolloClient({
    link,
    cache: new InMemoryCache(),
});


import 'sweetalert2/dist/sweetalert2.min.css';
import '@vueform/slider/themes/default.css';


import 'vue-datepicker-next/index.css';

// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import VueGoogleMaps from '@fawmi/vue-google-maps'

const vuetify = createVuetify({
    components,
    directives,
});

import ForecastStatus from '@/components/subscriptions/forecast-job-status'


const onBootApplication = () => {
    return createApp({
        setup() {
            provide(DefaultApolloClient, apolloClient)
        },
        render: () => h(App),
    })
        .use(Vue3ToggleButton)
        .use(VueFormWizard)
        .use(rangeCalendar)
        .use(pinia)
        .use(store)
        .use(router)
        .use(vuetify)
        .use(ToastPlugin)
        .use(require('vue-chartist'))
        .use(BootstrapVueNext)
        .use(VueApexCharts)
        .use(vClickOutside)
        .use(i18n)
        // .use({ render: h => h(AppWithClient)})
        .use(registerScrollSpy)
        .use(VueGoogleMaps, {
            load: {
                key: 'AIzaSyAm4-5Agqji5Tu6isdh-6Snn6dR8Yi-lFw',
                libraries: "places,geometry,drawing"
            },
            autobindAllEvents: true,
            installComponents: true
        })
        .component('AgGridVue', AgGridVue)
        .component('o-forecast-status', ForecastStatus)
        .directive("maska", vMaska)
}

if (process.env.VUE_APP_DSP_AUTHENTICATION_ENABLED) {

    const auth = DspAuth.getInstance()
    auth.register().then((result) => {
        if (result.isAuthenticated) {
            const app = onBootApplication()
            // Register mixin globally if needed
            app.mixin({
                methods: {
                    footerTableProps
                }
            })
            auth.secureRoutes(app, router).then(() => {
                app.mount('#app')
            })
        }
    })

} else {
    const app = onBootApplication()
    // Register mixin globally if needed
    app.mixin({
        methods: {
            footerTableProps
        }
    })
    app.mount('#app')
}

