<!--
    Copyright (C) Octillion Media LLC 2019
    All Rights Reserved.
-->

<template>
    <b-badge class="font-size-12" :variant="this.variant">{{ message }}</b-badge>
</template>

<script>
import { watch } from 'vue';
import { useSubscription } from "@vue/apollo-composable";
import gql from 'graphql-tag';

export default {
    props: {
        id: {
            type: Number,
            default: 0,
        },
    },
    mounted() {
        const variables = { forecastImpressionId: this.id.toString() }

        const { result } = useSubscription(gql`
        subscription forecastJobStatus($forecastImpressionId: ID!) {
            jobStatus(id: $forecastImpressionId) {
                id
                message
            }
        }
        `, variables)

        watch(
            result, 
            data => { 
                console.log('GQL DATA:', data)
                if (data != undefined) {
                    this.message = data.jobStatus.message
                    switch (this.message) {
                        case 'Completed':
                            this.variant = 'success'
                            break
                        case 'Failed':
                            this.variant = 'danger'
                            break
                    }
                }
            }, 
            { lazy: true }
        )
    },
    data() {
        return {
            message: 'Processing...',
            variant: 'info'
        }
    },
}
</script>
